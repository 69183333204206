import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Simple Technology with a Difference",
      description:
        "QR Code and NFC's have been about for years, but we have built our system with a difference to allow flexibility to customers without them having to worry about their details changing - update your information anytime, anywhere. Your details can change, so we make it easy for you to change tags as you need.",
      image: "./draws/undraw_details_8k13.svg",
    },
    {
      title: "Flexibility",
      description:
        "Create calls to actions straight from your TagTap page. Have a special event happening or are fund raising (such as JustGiving page)? - simply add it to your TagTap link for as long as you need it. Reusing your existing tag(s) is much simpler and cheaper too!",
      image: "./draws/undraw_editable_re_4l94.svg",
    },
    {
      title: "Statistics",
      description:
        "Get statistics on how many scans you've had, when they occured and other important analysis information. Giving you a new way of visualising where your leads come from.",
      image: "./draws/undraw_statistic_chart_38b6.svg",
    },
    {
      title: "Meeting the Team",
      description:
        "With our system, you can have multiple tags which provides you with a new and creative way of sharing your team with new and existing conections.",
      image: "./draws/undraw_team_re_0bfe.svg",
    },
    {
      title: "Eco Friendly",
      description:
        "Traditional business cards are so dated, along with the sigificant cost and wastage. Cut down on your negative environmental impact by having a shareable and edittable media.",
      image: "./draws/undraw_environment_iaus.svg",
    },
    
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
