import React, {useContext} from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import ContactSection from "../components/ContactSection";
import SocialLinks from "../components/SocialLinks";
import Footer from "../components/Footer";
import {getCustomerData} from "../helpers/api";
import { useRouter } from "../util/router.js";
import TagListSection from "../components/TagList";
import LoginSection from "../components/Login";
import { Route, Redirect, useLocation } from 'react-router-dom';
import {useAuth} from "../auth/authContext";
import CompleteSignupComponent from "../components/CompleteSignup";

function CompleteSignup(props) {
  const authContext = useAuth();
  const { user, newPasswordRequired, error, clearErrors, completeAccount, accountSetupDone } = authContext;

  if((user && newPasswordRequired == false) || accountSetupDone){
    return <Redirect to="/tags" />;
  }
  if(!user) {
    return <Redirect to="/" />; 
  }

  if(accountSetupDone){
    return <Redirect to={"/tags"} />
  }

  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
      />
      <CompleteSignupComponent 
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        error={error}
        clearErrors={clearErrors}
        user={user}
        completeAccount={completeAccount}
        accountSetupDone={accountSetupDone}
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default CompleteSignup;
