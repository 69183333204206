import React from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getCustomersTagRecords } from "../helpers/api";
import { useRouter } from "./../util/router.js";
import DetailUpdate from "../components/DetailUpdate";
import {useAuth} from "../auth/authContext";

function UpdatePage(props) {
  const authContext = useAuth();
  const { user, isAuthenticated } = authContext;
  const [tagData, setTagData] = useState('');
  const session = user.signInUserSession.idToken;
  const sessionToken = session.jwtToken;
  const router = useRouter();
  const tagId = router.params.id;

  useEffect(() => {
    if (!tagData) {
        getTagData();
    }
  }, []);


  
  const getTagData = async () => {
    const response = await getCustomersTagRecords(sessionToken, tagId);
    setTagData(response);
  };

  if(!tagData){
    return (
      <>Loading...</>
    )
  }
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="../blacklogo.png"
        logoInverted="../whitelogo.png"
        isAuthenticated={isAuthenticated}
      />
      <DetailUpdate
        user={user}
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        tagId={tagData.tagId}

        nickname={tagData.details.nickname ?? undefined}
        title={tagData.details.header?.title ? tagData.details.header.title : undefined}
        subtitle={tagData.details.header?.subtitle ? tagData.details.header.subtitle : undefined}
        companyLogo={tagData.details.header?.logo ? tagData.details.header.logo : undefined}
        officeSummaryEnabled={tagData.details.officeSummaryEnabled}
        organisation={tagData.details.organisation}
        addressFirstLine={tagData.details.workAddress?.label ? tagData.details.workAddress.label : undefined}
        addressSecondLine={tagData.details.workAddress?.street ? tagData.details.workAddress.street : undefined}
        addressTown={tagData.details.workAddress?.city ? tagData.details.workAddress.city : undefined}
        addressCounty={tagData.details.workAddress?.stateProvince ? tagData.details.workAddress.stateProvince : undefined}
        addressPostCode={tagData.details.workAddress?.postalCode ? tagData.details.workAddress.postalCode : undefined}
        officeNumber={tagData.details.officeNumber ?? undefined}
        mobileNumber={tagData.details.mobileNumber ?? undefined}
        emailAddress={tagData.details.emailAddress ?? undefined}
        embedSrc={tagData.details.embedSrc ?? undefined}

        facebook={tagData.details.socialLinks?.facebook ? tagData.details.socialLinks.facebook : undefined}
        linkedIn={tagData.details.socialLinks?.linkedIn ? tagData.details.socialLinks.linkedIn : undefined}
        twitter={tagData.details.socialLinks?.twitter  ? tagData.details.socialLinks.twitter : undefined}
        github={tagData.details.socialLinks?.github ? tagData.details.socialLinks.github : undefined}
        instagram={tagData.details.socialLinks?.instagram ? tagData.details.socialLinks.instagram : undefined}
        customLinks={tagData.details.socialLinks?.custom ? tagData.details.socialLinks.custom : []}

        buttonText="Update Tag"
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="../blacklogo.png"
        logoInverted="../whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default UpdatePage;
