import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useRouter } from "../util/router";
import { QRCode } from "react-qrcode-logo";
const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: "320px",
    backgroundColor: "#efefef",
    // Use some CSS to give the embedded map a dark mode
    // since Google Maps embeds don't support it yet.
    filter: theme.palette.type === "dark" && "invert(90%)",
  },
}));


function TagListSection(props) {
  const classes = useStyles();
  const router = useRouter();

  
  if(!props.tagData){
    return <>Loading Tag Data...</>
  }

  
  if(props.tagData.length <= 0){
    return (
      <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="lg">
    <Typography>
      You currently have no tags, please contact support.
    </Typography>
    </Container>
    </Section>
    )
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="lg">

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Tag ID</TableCell>
            <TableCell align="right">Nickname</TableCell>
            <TableCell align="right">Preview</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tagData.map((row) => (
            
            <TableRow
              key={row.tagId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.tagId}
              </TableCell>
              <TableCell align="right">{row.tagNickname}</TableCell>
              <TableCell align="right">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => { router.push(`/scan/${row.tagId}?preview=true`) }}
                    >
                    <span>Preview</span>
                </Button>
              </TableCell>
              <TableCell align="right">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => { router.push(`/tags/${row.tagId}`) }}
                    >
                    <span>Edit</span>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Container>
    </Section>
  );
}

export default TagListSection;
