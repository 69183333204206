import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Typography } from "@material-ui/core";
import { useRouter } from "../util/router";

const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: "320px",
    backgroundColor: "#efefef",
    // Use some CSS to give the embedded map a dark mode
    // since Google Maps embeds don't support it yet.
    filter: theme.palette.type === "dark" && "invert(90%)",
  },
}));


function PrivacySection(props) {
  const classes = useStyles();
  const router = useRouter();


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">

      <Typography variant="h3">Privacy Poilcy</Typography>
      <Typography> All applications published by {">"}tminal are covered by the privacy policy last updated 22 August 2012.</Typography>
      
       <Typography variant="h6">We collect personal and activity data, which may be linked</Typography>
        <Typography variant="body2">We use technologies like cookies (small files stored on your browser), web beacons, or unique device identifiers to identify your computer or device so we can deliver a better experience. Our systems also log information like your browser, operating system and IP address.

        We also may collect personally identifiable information that you provide to us, such as your name, address, phone number or email address. With your permission, we may also access other personal information on your device, such as your phone book, calendar or messages, in order to provide services to you. If authorized by you, we may also access profile and other information from services like Facebook.

        Our systems may associate this personal information with your activities in the course of providing service to you (such as pages you view or things you click on or search for).

        We do not knowingly contact or collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us so we can promptly obtain parental consent or remove the information.
        </Typography>
<Typography variant="h6">We may collect but do not share your location history</Typography>
<Typography variant="body2">To customize our service for you, we may collect and store your precise geographic location as part of your user profile. This data generally is not shared with others. We may ask for your consent on your first use of the service.</Typography>

<Typography variant="h6">You can request to see your personal data</Typography>
<Typography variant="body2">You can sign into your account to see any personally identifiable information we have stored, such as your name, email, address or phone number. You can also contact us by email to request to see this information.</Typography>

<Typography variant="h6">We keep personal data until you delete it</Typography>
<Typography variant="body2">We remove personally identifiable information (such as your name, address, email or phone number) and other preferences associated with your account promptly after you delete your account. We may retain other data indefinitely.</Typography>

<Typography variant="h6">We don't share your personal data with other companies</Typography>
<Typography variant="body2">We generally do not share personally identifiable information (such as name, address, email or phone) with other companies.</Typography>

<Typography variant="h6">Service providers access data on our behalf</Typography>
<Typography variant="body2">In order to serve you, we may share your personal and anonymous information with other companies, including vendors and contractors. Their use of information is limited to these purposes, and subject to agreements that require them to keep the information confidential. Our vendors provide assurance that they take reasonable steps to safeguard the data they hold on our behalf, although data security cannot be guaranteed.

Analytics companies may access anonymous data (such as your IP address or device ID) to help us understand how our services are used. They use this data solely on our behalf. They do not share it except in aggregate form; no data is shared as to any individual user. Click to see company privacy policies that govern their use of data.

- <a href="https://marketingplatform.google.com/about/">Google Analytics</a>
</Typography>

<Typography variant="h6">We take steps to protect personal information</Typography>
<Typography variant="body2">We take reasonable steps to secure your personally identifiable information against unauthorized access or disclosure. We encrypt transmission of data on pages where you provide payment information. However, no security or encryption method can be guaranteed to protect information from hackers or human error.

Information we collect may be stored or processed on computers located in any country where we do business.</Typography>

<Typography variant="h6">Special situations may require disclosure of your data</Typography>
<Typography variant="body2">To operate the service, we also may make identifiable and anonymous information available to third parties in these limited circumstances: (1) with your express consent, (2) when we have a good faith belief it is required by law, (3) when we have a good faith belief it is necessary to protect our rights or property, or (4) to any successor or purchaser in a merger, acquisition, liquidation, dissolution or sale of assets. Your consent will not be required for disclosure in these cases, but we will attempt to notify you, to the extent permitted by law to do so.</Typography>

<Typography variant="h6">You can review more privacy-related information</Typography>
<Typography variant="body2">Our privacy policy may change from time to time. If we make any material changes to our policies, we will place a prominent notice on our website or application. If the change materially affects registered users, we will send a notice to you by email, push notification or text.</Typography>

      </Container>
    </Section>
  );
}

export default PrivacySection;
