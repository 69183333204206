import axios from "axios";

const url = "https://api.tagtap.uk/tag";

export function getCustomersTags(token) {
    const customerTagUrl = "https://api.tagtap.uk/customer";
    
    return new Promise((resolve, reject) => {
        axios.get(`${customerTagUrl}`, {
            headers: {
                "Authorization": `${token}`,
            },
        })
        .then(res => {
            const customerData = res.data;
            
            if(customerData.length <= 0){
                return reject("No Data");
            }
            
            resolve(customerData);
        });
    });
}

export function sendUserDataStats(data){
    const customerTagUrl = `https://api.tagtap.uk/stats`;
    
    return new Promise((resolve, reject) => {

    axios.post(customerTagUrl, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => {
          resolve(res);
      }).catch(error => {
          reject("failed");
      });


    });
}

export function getCustomersTagRecords(token, tagId) {
    const customerTagUrl = `https://api.tagtap.uk/customer/tag/${tagId}`;
    
    return new Promise((resolve, reject) => {
        axios.get(`${customerTagUrl}`, {
            headers: {
                "Authorization": `${token}`,
            },
        })
        .then(res => {
            const customerData = res.data;
            
            if(customerData.length <= 0){
                return reject("No Data");
            }
            
            resolve(customerData);
        });
    });
}

export function getCustomerData(tagId){
    return new Promise((resolve, reject) => {
        axios.get(`${url}/${tagId}`, {
            headers: {
                // "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
        })
        .then(res => {
            const persons = res.data;
            
            if(persons.length <= 0){
                return reject("No Data");
            }

            const myData = persons[0].details;
            if(!myData) return reject("No Data");
        
            // // TODO: Needs to come from the API
            // myData.socialLinks["custom"] = [{
            //     link: "mailto:jamie@tminal.com",
            //     avatar: "../email.png",
            //     tag: "Shop Email"
            // },
            // {
            //     link: "https://tminal.com",
            //     avatar: "../safari.png",
            //     tag: "Shop Website"
            // },
            // {
            //     link: "app store",
            //     avatar: "../app-store.png",
            //     tag: "find our iOS app"
            // },
            // {
            //     link: "android store",
            //     avatar: "../playstore.png",
            //     tag: "find out Android app"
            // }];
            resolve(myData);
        });
    });
}        


// export function login(){

//     return new Promise((resolve, reject) => {
//         axios.get("https://14i39aa0of.execute-api.eu-west-1.amazonaws.com/dev/login")
//         .then(res => {
//             const data = JSON.parse(res.data);
//             resolve({
//                 token: data.token,
//             });
//         });
//     });
    
// }


function dataSubmit(tagId){

    return new Promise((resolve, reject) => {
        axios.get(url)
        .then(res => {
            const persons = res.data;
            resolve({
                ...persons.body.details,
                contactSection: {
                    enabled: false,
                },
                headerCompanyLogo: "https://www.deepcrawl.com/wp-content/themes/deepcrawl/images/deepcrawl-logo.svg",
                headerTitle: "Thanks for scanning our tag!",
                headerSubtitle: "It was nice to meet you, keep in touch with our details below.",
                embedSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.176569329384!2d115.64515051624444!3d-33.340336599257625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a2e1d6e1f2832f7%3A0xe4813eb823ccbe30!2sFlorist+Gump!5e0!3m2!1sen!2sus!4v1564947283991!5m2!1sen!2sus"
            });
        });
    });
}
export const updateData = { dataSubmit };
