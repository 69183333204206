import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useRouter } from "../util/router";
import { useState } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: "320px",
    backgroundColor: "#efefef",
    // Use some CSS to give the embedded map a dark mode
    // since Google Maps embeds don't support it yet.
    filter: theme.palette.type === "dark" && "invert(90%)",
  },
}));

function LoginSection(props) {
  const classes = useStyles();
  const router = useRouter();

  // const authContext = useContext(AuthContext);
  // const { login, error, clearErrors, isAuthenticated } = authContext;

  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  // if (isAuthenticated) {
  //   return <Redirect to='/tags' />;
  // }

  const { email, password } = user;

  const onChange = (e) =>
      setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
      e.preventDefault();
      props.clearErrors();
      if (password === '' || email === '') {
        //   setAlert('Please fill in all fields', 'danger');
      } else {
          await props.login({
              email,
              password
          });
      }
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">

        <div className='form-container'>
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                    <span>                        
                        <TextField
                          variant="outlined"
                          type="email"
                          label="Email Address"
                          name="email"
                          fullWidth={true}
                          value={email}
                          onChange={onChange}
                          required
                        />
                    </span>
                </div>
                <p />
                <div className='form-group'>
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                      name="password"
                      fullWidth={true}
                      value={password}
                      onChange={onChange}
                      required
                    />
                </div>
                <p />
                <span>
                <Button
                  variant="outlined"
                  type='submit'
                  value='login'
                >Login</Button>
                </span>
                <p />
                <span>
                <Link to="/forgotten-password">Forgotten Password</Link>
                </span>
               
            </form>
            
        </div>
  
        { props.error &&
              <h3 className="error"> { props.error } </h3> }

      </Container>
    </Section>
  );
}

export default LoginSection;
