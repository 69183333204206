import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: "320px",
    backgroundColor: "#efefef",
    // Use some CSS to give the embedded map a dark mode
    // since Google Maps embeds don't support it yet.
    filter: theme.palette.type === "dark" && "invert(90%)",
  },
}));

function ContactSection(props) {
  const classes = useStyles();

  if (props.enabled){
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="lg">

        <Grid container={true} spacing={4}>
          {props.embedSrc != undefined ?
          <Grid item={true} xs={12} md={6}>
          <iframe
            className={classes.map}
            src={props.embedSrc}
            title="Contact Map"
            frameBorder={0}
          />
          </Grid> : <></>
          }
          
          
          <Grid item={true} xs={12} md={6}>
          <SectionHeader
            title={props.title}
            size={4}
            textAlign="center"
          />
          <p />
          <Typography>
            {props.emailAddress}
          </Typography>
          <p />
          <Typography>
            {props.addressFirstLine}
            </Typography>
            <Typography>
            {props.addressSecondLine}
            </Typography>
            <Typography>
            {props.addressTown}
            </Typography>
            <Typography>
            {props.addressCounty}
            </Typography>
            <Typography>
            {props.addressPostCode}
          </Typography>
          <p />
          <Typography>
            {props.officeNumber}
           </Typography>
            <Typography>
            {props.mobileNumber}
          </Typography>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
  }
  return (<></>);
}

export default ContactSection;
