import React from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useRouter } from "./../util/router.js";
import TagListSection from "../components/TagList";
import { getCustomersTags } from "../helpers/api";
import {useAuth} from "../auth/authContext";

function TagListPage(props) {
  const [tagData, setTagData] = useState([]);

  const router = useRouter();
  const authContext = useAuth();
  const { user } = authContext;

  const session = user.signInUserSession.idToken;
  const sessionToken = session.jwtToken;

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (tagData.length <= 0) {
        getTagData();
    }
  }, []);

  const getTagData = async () => {
    const response = await getCustomersTags(sessionToken);
    setTagData(response);
  };
    
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
        isAuthenticated={props.isAuthenticated}
      />
      <TagListSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        tagData={tagData}
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default TagListPage;
