import React, { createRef, useEffect, useRef } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import HeroSection2 from "./../components/HeroSection2";
import FeaturesSection from "./../components/FeaturesSection";
import FaqSection from "./../components/FaqSection";
import Footer from "../components/Footer";
import PricingSection from "./../components/PricingSection";
import {useAuth} from "../auth/authContext";

function IndexPage(props) {
  const authContext = useAuth();
  const { isAuthenticated, loadUser, user, logout } = authContext;

  useEffect(() => {
    if(!isAuthenticated){
        (async function() {
            await loadUser();
        })();
    }
  }, [user]);

  return (
    <>
      <Meta title="TagTap" />
      <Navbar
        color="default"
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
        isAuthenticated={isAuthenticated}
        logout={logout}
      />
      <HeroSection2
        bgColor="default"
        size="large"
        bgImage="./cut.png"
        bgImageOpacity={0.3}
        title="A centralised social and URL sharing product"
        subtitle="Instead of reaching for a pen or a physical card, use TagTap to share your important contact details."
        image="./draws/undraw_conversation_re_c26v.svg"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
       <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Choose the plan that makes sense for you. All plans include a QR Code delivered to your inbox."
      />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default IndexPage;
