import React, {useContext} from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import ContactSection from "../components/ContactSection";
import SocialLinks from "../components/SocialLinks";
import Footer from "../components/Footer";
import {getCustomerData} from "../helpers/api";
import { useRouter } from "../util/router.js";
import TagListSection from "../components/TagList";
import { Route, Redirect, useLocation } from 'react-router-dom';
import {useAuth} from "../auth/authContext";
import ForgottenPasswordSection from "../components/ForgottenPassword";

function ForgottenPassword(props) {
  const authContext = useAuth();
  const { user, newPasswordRequired, error, clearErrors, forgottenPassword, passwordRequestSent, passwordCodeSent, setNewPassword } = authContext;
  
  if(user && newPasswordRequired == false){
    return <Redirect to="/tags" />;
  }
  
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
      />
      <ForgottenPasswordSection 
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        error={error}
        clearErrors={clearErrors}
        user={user}
        passwordRequestSent={passwordRequestSent} // Request sent to AWS to change password
        passwordCodeSent={passwordCodeSent} // Code requested to be delivered to users mailbox
        forgottenPassword={forgottenPassword}
        setNewPassword={setNewPassword}
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="blacklogo.png"
        logoInverted="whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default ForgottenPassword;
