import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useRouter } from "../util/router";
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../auth/authContext';
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: "320px",
    backgroundColor: "#efefef",
    // Use some CSS to give the embedded map a dark mode
    // since Google Maps embeds don't support it yet.
    filter: theme.palette.type === "dark" && "invert(90%)",
  },
}));

function CompleteSignupComponent(props) {

  const [user, setUser] = useState({
    email: props.user.challengeParam.attributes ? props.user.challengeParam.attributes.email : props.user.challengeParam.userAttributes.email,
    password: '',
    givenName: "",
    familyName: "",
    phoneNumber: "",
    firstLine: "",
    secondLine: "",
    town: "",
    postcode: ""
  });

  const { email, password, givenName, familyName, phoneNumber, firstLine, secondLine, town, postcode } = user;

  const onChange = (e) =>
      setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
      e.preventDefault();
      props.clearErrors();
        await props.completeAccount({
            email,
            password,
            givenName,
            familyName,
            phoneNumber,
            firstLine,
            secondLine,
            town,
            postcode
        });
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">
    
        <div className='form-container'>
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                    <span>                        
                        <TextField
                          variant="outlined"
                          type="email"
                          label="Email Address"
                          name="email"
                          fullWidth={true}
                          value={email}
                          disabled
                        />
                    </span>
                </div>
                <p />
                <div className='form-group'>
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                      name="password"
                      fullWidth={true}
                      value={password}
                      onChange={onChange}
                      required
                    />
                </div>
                <p />

                <TextField
                      variant="outlined"
                      type="text"
                      label="First Name"
                      name="givenName"
                      fullWidth={true}
                      value={givenName}
                      onChange={onChange}
                      required
                    />
<p />
                <TextField
                      variant="outlined"
                      type="text"
                      label="Last Name"
                      name="familyName"
                      fullWidth={true}
                      value={familyName}
                      onChange={onChange}
                      required
                    />
<p />
                <TextField
                      variant="outlined"
                      type="text"
                      label="Phone Number"
                      name="phoneNumber"
                      fullWidth={true}
                      value={phoneNumber}
                      onChange={onChange}
                      required
                    />
                    <p />
                <Typography>
                    Address
                </Typography>
                <p />
                <TextField
                    variant="outlined"
                    type="text"
                    label="First Line"
                    name="firstLine"
                    fullWidth={true}
                    value={firstLine}
                    onChange={onChange}
                    required
                />
                <p />
                <TextField
                    variant="outlined"
                    type="text"
                    label="Second Line"
                    name="secondLine"
                    fullWidth={true}
                    value={secondLine}
                    onChange={onChange}
                    required
                />
                <p />
                <TextField
                    variant="outlined"
                    type="text"
                    label="Town"
                    name="town"
                    fullWidth={true}
                    value={town}
                    onChange={onChange}
                    required
                />
<p />
                <TextField
                    variant="outlined"
                    type="text"
                    label="Postcode"
                    name="postcode"
                    fullWidth={true}
                    value={postcode}
                    onChange={onChange}
                    required
                />
                    <p />
                <span>
                <Button
                  variant="outlined"
                  type='submit'
                  value='login'
                >Submit</Button>
                </span>
            </form>
            
        </div>
  
        { props.error &&
              <h3 className="error"> { props.error } </h3> }

      </Container>
    </Section>
  );
}

export default CompleteSignupComponent;
