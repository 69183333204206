import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import { Auth } from "aws-amplify";
import { Cookies } from 'react-cookie-consent';

import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    NEW_PASSWORD_REQUIRED,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    ACCOUNT_COMPLETE_FAILED,
    ACCOUNT_COMPLETE_FINISHED,
    FORGOTTEN_PASSWORD_SENT,
    FORGOTTEN_PASSWORD_SUCCESS,
    FORGOTTEN_PASSWORD_FAIL
} from '../types';

const AuthProvider = (props) => {
    const initialState = {
        passwordCodeSent: false,
        passwordRequestSent: false,
        accountSetupDone: false,
        user: null,
        isAuthenticated: false,
        error: null,
        isSendingRequest: false,
        newPasswordRequired: false,
    };

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    // LOAD USER
    const loadUser = async () => {
        try {
            state.isSendingRequest = true;
            state.user = await Auth.currentAuthenticatedUser();
            if (state.user) {
                // state.isAuthenticated = true;
                dispatch({
                    type: USER_LOADED,
                    user: state.user,
                    isAuthenticated: true,
                    isSendingRequest: false
                });
            } else {
                state.isAuthenticated = false;
                state.isSendingRequest = false;
                state.user = undefined;
                dispatch({
                    type: AUTH_ERROR
                });
            }
          } catch (e) {
            state.isAuthenticated = false;
            state.isSendingRequest = false;
            state.user = undefined;
            dispatch({
                type: AUTH_ERROR
            });
          }
    };

    // LOGOUT
    const logout = async () => {
        try {
            state.isSendingRequest = true;
            await Auth.signOut();
            state.isAuthenticated = false;
            state.user = undefined;
            Cookies.remove("*", {
                domain: "tagtap.uk",
                secure: true
            });
            dispatch({
                type: LOGOUT,
            });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    // LOGIN
    const login = async (formData) => {
        try {
            state.isSendingRequest = true;
            const user = await Auth.signIn(formData.email, formData.password);

            // Check if new password required
            if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // Complete signup
                state.error = null;
                return dispatch({
                    type: NEW_PASSWORD_REQUIRED,
                    user: user,
                    error: null,
                });
            }
            
            await Auth.currentAuthenticatedUser();
            dispatch({
                type: LOGIN_SUCCESS,
                user: user,
            });

        } catch (error) {
            dispatch({
                type: LOGIN_FAIL,
                action: error.message ? error.message : error
            });
        }
    };

    async function completeAccount(formData) {
        try {
            await Auth.completeNewPassword(
                state.user,               // the Cognito User Object
                formData.password,       // the new password
                {
                    given_name: formData.givenName,
                    family_name: formData.familyName,
                    phone_number: formData.phoneNumber,
                    address: `${formData.firstLine},\n${formData.secondLine},\n${formData.town},\n${formData.postcode}`
                    
                }
            );
            
            dispatch({
                type: ACCOUNT_COMPLETE_FINISHED,
                user: state.user,
                isAuthenticated: true,
                newPasswordRequired: false,
                accountSetupDone: true,
            });
            
          } catch(error) {
            console.log(error);
            dispatch({
                type: ACCOUNT_COMPLETE_FAILED,
                action: error.message ? error.message : error
            });
          }
        
    }

    // Clear Errors
    const clearErrors = async () => {
        dispatch({
            type: CLEAR_ERRORS
        });
    };

    async function forgottenPassword(email) {
        state.passwordCodeSent = false;
        try {
            await Auth.forgotPassword(email);
            state.passwordCodeSent = true;
            dispatch({
                type: FORGOTTEN_PASSWORD_SENT,
                passwordCodeSent: true
            });
        } catch(error) {
        console.log(error);
        dispatch({
            type: FORGOTTEN_PASSWORD_FAIL,
            error: error
        });
        }
    }

    async function setNewPassword(form) {
        try {
            await Auth.forgotPasswordSubmit(form.email, form.code, form.password);
            dispatch({
                type: FORGOTTEN_PASSWORD_SUCCESS,
                passwordRequestSent: true
            });
        } catch(error) {
        console.log(error);
        dispatch({
            type: FORGOTTEN_PASSWORD_FAIL,
            passwordRequestSent: false,
            error: error
        });
        }
    }

    async function authStatus() {
        try {
          state.isSendingRequest = true;
          let session = await Auth.currentSession();
          let user = await Auth.currentAuthenticatedUser();
          if (session && user) { dispatch({ type: "LOGIN", payload: user }) };
          
        } catch(error) {
          console.log(error);
        }
      };


    return (
        <AuthContext.Provider
            value={{
                state,
                isAuthenticated: state.isAuthenticated,
                user: state.user,
                error: state.error,
                isSendingRequest: state.isSendingRequest,
                passwordRequestSent: state.passwordRequestSent,
                passwordCodeSent: state.passwordCodeSent,
                accountSetupDone: state.accountSetupDone,
                newPasswordRequired: state.newPasswordRequired,
                login,
                logout,
                loadUser,
                clearErrors,
                authStatus,
                forgottenPassword,
                completeAccount,
                setNewPassword
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
