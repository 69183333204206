import React, {useContext} from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import ContactSection from "../components/ContactSection";
import SocialLinks from "../components/SocialLinks";
import Footer from "../components/Footer";
import {getCustomerData} from "../helpers/api";
import { useRouter } from "../util/router.js";
import TagListSection from "../components/TagList";
import LoginSection from "../components/Login";
import { Route, Redirect, useLocation } from 'react-router-dom';
import {useAuth} from "../auth/authContext";
import PrivacySection from "../components/Privacy";

function Privacy(props) {
    const authContext = useAuth();
    const { isAuthenticated } = authContext;

  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="../blacklogo.png"
        logoInverted="../whitelogo.png"
        isAuthenticated={isAuthenticated}
      />
      <PrivacySection />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="../blacklogo.png"
        logoInverted="../whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default Privacy;
