import React from "react";
import { useState, useEffect } from "react";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import ContactSection from "../components/ContactSection";
import SocialLinks from "../components/SocialLinks";
import Footer from "../components/Footer";
import { getCustomerData, sendUserDataStats } from "../helpers/api";
import { useRouter } from "./../util/router.js";
import { v4 as uuidv4 } from 'uuid';
import spinner from "./spinner.gif"

function getUuid(){
  const scanner = localStorage.getItem('scanner');
  if(scanner) return scanner;
  
  const newUuid = uuidv4();
  localStorage.setItem('scanner', newUuid);
  return newUuid;
}



function CardPage(props) {
  const [tagData, setTagData] = useState('');
  // const [geolocation, setGeolocation] = useState('');

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isPreviewMode = params.get('preview');

  // window.navigator.geolocation.getCurrentPosition(success, null, {
  //   enableHighAccuracy: true,
  //   timeout: 5000,
  //   maximumAge: 0
  // })
  
  // function success(pos) {
  //   setGeolocation(pos.coords);
  // }
  
  function sendTagLoaded(userLocalId, tagId, type) {
    sendUserDataStats({
      // location: geolocation,
      useragent: navigator.userAgent,
      tagId: tagId,
      scannerId: userLocalId,
      event: type
    });
  }

  useEffect(() => {
    if (!tagData) {
        getTagData();
    }
    if(tagData && !isPreviewMode){
        // Store in Local storage (whether its a valid tag or not - i.e. prior to data retrieval)
        const userLocalId = getUuid();
        sendTagLoaded(userLocalId, tagId, "tag-loaded");
    }
  }, [tagData]);

  const router = useRouter();
  const tagId = router.params.id;

  if(tagId === undefined) {
    return router.replace("/");
  };



  const getTagData = async () => {
    try {
      const response = await getCustomerData(tagId);
      setTagData(response);
    } catch (error) {
      // If no data returned - redirect them to home screen
      return router.replace("/");
    }
  };

  

  

  if(!tagData){
    return (
      <>
      <img
        src={spinner}
        style={{ width: '340px', margin: 'auto', display: 'block' }}
        alt="Loading..."
      />
    </>
    )
  }

  
    
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="../blacklogo.png"
        logoInverted="../whitelogo.png"
        cardScan={true}
      />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        companyLogo={tagData.header.logo}
        title={tagData.header.title}
        subtitle={tagData.header.subtitle}
      />
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Information"
        enabled={tagData.officeSummaryEnabled}
        organisation={tagData.organisation}
        addressFirstLine={tagData.workAddress.label ?? undefined}
        addressSecondLine={tagData.workAddress.street ?? undefined}
        addressTown={tagData.workAddress.city ?? undefined}
        addressCounty={tagData.workAddress.stateProvince ?? undefined}
        addressPostCode={tagData.workAddress.postalCode ?? undefined}
        officeNumber={tagData.officeNumber ?? undefined}
        mobileNumber={tagData.mobileNumber ?? undefined}
        emailAddress={tagData.emailAddress ?? undefined}
        embedSrc={tagData.embedSrc ?? undefined}
      /> 
      <SocialLinks
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Find us on..."
        subtitle=""
        facebook={tagData.socialLinks.facebook ?? undefined}
        linkedIn={tagData.socialLinks.linkedIn ?? undefined}
        twitter={tagData.socialLinks.twitter ?? undefined}
        github={tagData.socialLinks.github ?? undefined}
        instagram={tagData.socialLinks.instagram ?? undefined}
        custom={tagData.socialLinks.custom ?? undefined}
      />
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} - Built by >tminal`}
        logo="../blacklogo.png"
        logoInverted="../whitelogo.png"
        sticky={true}
      />
    </>
  );
}

export default CardPage;
