import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      

      <Container>
      
        <Box textAlign="center">
          <Card style={{margin: 'auto', width: '90%', backgroundColor: 'transparent', boxShadow: 'none'}}>
            <img 
              style={{maxHeight: '350px', maxWidth:'100%'}}
              src={props.companyLogo}
              alt="new"
            />
          </Card>
          <p></p>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
          />
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
