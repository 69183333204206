import axios from "axios";


function submit(data, token, tagId) {
  const url = `https://api.tagtap.uk/customer/tag/${tagId}`;

  return new Promise((resolve, reject) => {
    axios.post(url, JSON.stringify(data), {
      headers: {
        "Authorization": `${token}`,
        "Content-Type": "application/json",
      },
    })
    .then(res => {
        resolve(res);
    });
});

}

const tagUpdate = { submit };

export default tagUpdate;
