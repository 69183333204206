export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";
export const ACCOUNT_COMPLETE_FAILED = "ACCOUNT_COMPLETE_FAILED";
export const ACCOUNT_COMPLETE_FINISHED = "ACCOUNT_COMPLETE_FINISHED";
export const FORGOTTEN_PASSWORD_SENT = "FORGOTTEN_PASSWORD_SENT";
export const FORGOTTEN_PASSWORD_SUCCESS = "FORGOTTEN_PASSWORD_SUCCESS";
export const FORGOTTEN_PASSWORD_FAIL = "FORGOTTEN_PASSWORD_FAIL";