import React, { useEffect, useState, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { Typography } from "@material-ui/core";
import tagUpdate from "../util/data-submit";
import Container from "@material-ui/core/Container";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ArrowDropDown } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NativeSelect from "@material-ui/core/NativeSelect";

function DetailUpdate(props) {
  const icons = [{
    icon: "app-store",
    name: "App Store"
  },
  {
    icon: "email",
    name: "Email"
  },
  {
    icon: "facebook",
    name: "Facebook"
  },
  {
    icon: "git",
    name: "GitHub"
  },
  {
    icon: "phone",
    name: "Phone"
  },
  {
    icon: "playstore",
    name: "Google Play Store"
  },
  {
    icon: "instagram",
    name: "Instagram"
  },
  {
    icon: "linkedin",
    name: "LinkedIn"
  },
  {
    icon: "safari",
    name: "Safari"
  },
  {
    icon: "skype",
    name: "Skype"
  },
  {
    icon: "soundcloud",
    name: "Soundcloud"
  },
  {
    icon: "twitter",
    name: "Twitter"
  },
  {
    icon: "whatsapp",
    name: "WhatsApp"
  },
  {
    icon: "youtube",
    name: "YouTube"
  }
  ]
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();
  
    // State to store value from the input field
    const [tagLabelInput, setTagLabelInput] = useState("");
    const [tagIconInput, setTagIconInput] = useState("");
    const [tagLinkInput, setTagLinkInput] = useState("");

    // Input Field handler
    const handleTagLabelInput = (e) => {
      setTagLabelInput(e.target.value);
    };
    const handleTagIconInput = (e) => {
      setTagIconInput(e.target.value);
    };
    const handleTagLinkInput = (e) => {
      setTagLinkInput(e.target.value);
    };
  
    // Reset Input Field handler
    const resetInputField = () => {
      setTagLabelInput("");
      setTagIconInput("");
      setTagLinkInput("");
    };

  const [customLinks, setCustomLinks] = useState(props.customLinks);

  function deleteFromArray(itemKey){
    const newLinks = customLinks;
    const index = newLinks.findIndex(a => a.id === itemKey);
    newLinks.splice(index, 1);
    setCustomLinks([...newLinks]);
    resetInputField();
  }
  function addToArray(item) {
    const currentLinks = customLinks;
    setCustomLinks([...currentLinks, {
      id: currentLinks.length+1,
      link: item.link,
      label: item.label,
      icon: item.icon}]);
    resetInputField();
  }

  const onSubmit = async (data) => {
    const session = props.user.signInUserSession.idToken;
    const sessionToken = session.jwtToken;

    // Show pending indicator
    setPending(true);

    // Ensure to include this in the data request!
    data.custom=customLinks;

    tagUpdate
      .submit({
        ...data,
      }, sessionToken, props.tagId)
      .then(() => {
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your tag has been updated!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <Container maxWidth="lg">

      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid container={true} spacing={2}>
          

        <Grid item={true} xs={12} md={12}>
          <Typography>
            Title Data
          </Typography>
          </Grid>


          <Grid item={true} xs={12} md={6}>
          <TextField
              variant="outlined"
              type="text"
              label="Tag Nickname"
              name="tag-nickname"
              defaultValue={props.nickname}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          
          <Grid item={true} xs={12} md={6}>
          <TextField
              variant="outlined"
              type="text"
              label="Header Title"
              name="header-title"
              defaultValue={props.title}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
            </Grid>

            <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Header Subtitle"
              name="header-subtitle"
              defaultValue={props.subtitle}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
            </Grid>

            <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Header Logo"
              name="header-logo"
              defaultValue={props.companyLogo}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
            </Grid>

          <Grid item={true} xs={12} md={12}>
            <Typography>
              Contact Information
            </Typography>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Organisation Name"
              name="organisation-name"
              defaultValue={props.organisation}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>

          <Grid item={true} xs={12} md={12}>
          <Typography>
            Work Address
          </Typography>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <FormControlLabel control={<Checkbox inputRef={register()} defaultChecked={props.officeSummaryEnabled} name="office-summary-enabled" />} label="Enable Office Address Summary" />
          </Grid>

          <Grid item={true} xs={12} md={6}>
          </Grid>
          
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="First Line"
              name="address-first-line"
              defaultValue={props.addressFirstLine}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Second Line"
              name="address-second-line"
              defaultValue={props.addressSecondLine}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Town"
              name="address-town"
              defaultValue={props.addressTown}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="County"
              name="address-county"
              defaultValue={props.addressCounty}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Postcode"
              name="address-postcode"
              defaultValue={props.addressPostCode}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="email"
              label="Email"
              name="email"
              defaultValue={props.emailAddress}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Office Number"
              name="office-number"
              defaultValue={props.officeNumber}
              error={errors.message ? true : false}
              helperText={errors.message && errors.message.message}
              fullWidth={true}
              inputRef={register()}
            />
            </Grid>
            <Grid item={true} xs={12} md={6}>

            <TextField
              variant="outlined"
              type="text"
              label="Mobile Number"
              name="mobile-number"
              defaultValue={props.mobileNumber}
              error={errors.message ? true : false}
              helperText={errors.message && errors.message.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          
          <Grid item={true} xs={12} md={12}>
          <Typography>
            Default Social Links
          </Typography>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Facebook"
              name="facebook"
              defaultValue={props.facebook}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="LinkedIn"
              name="linkedin"
              defaultValue={props.linkedIn}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Twitter"
              name="twitter"
              defaultValue={props.twitter}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="GitHub"
              name="github"
              defaultValue={props.github}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <TextField
              variant="outlined"
              type="text"
              label="Instagram"
              name="instagram"
              defaultValue={props.facebook}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name.message}
              fullWidth={true}
              inputRef={register()}
            />
          </Grid>
          
          <Grid item={true} xs={12} md={12}>
          <Typography>
            Custom Links
          </Typography>
          </Grid>

          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Link</TableCell>
            <TableCell align="left">Label</TableCell>
            <TableCell align="left">Icon</TableCell>
            <TableCell align="left">Action</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {customLinks.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <TextField value={row.link}></TextField>
                </TableCell>
                <TableCell align="left">
                  <TextField value={row.label}></TextField>
                </TableCell>
                <TableCell align="left">

                
                <NativeSelect
                  defaultValue={row.icon}
                  onChange={handleTagIconInput}
                  disabled={true}
                >
                  {icons.map(({ name, icon }, index) => <option key={index} value={icon}>{name}</option>)}
                </NativeSelect>
                </TableCell>
                <TableCell align="left"><Button onClick={() => {
                  deleteFromArray(row.id);
                }}>Delete</Button></TableCell>
              </TableRow>
            ))}

            <TableRow
              key="new"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              <TableCell component="th" scope="row">
                <TextField value={tagLinkInput} onChange={handleTagLinkInput}/>
              </TableCell>
              <TableCell align="left">
                <TextField value={tagLabelInput} onChange={handleTagLabelInput}/>
              </TableCell>
              <TableCell align="left">
              <NativeSelect
                  placeholder="Choose Icon"
                  onChange={handleTagIconInput}
                >
                  {icons.map(({ name, icon }, index) => <option key={index} value={icon}>{name}</option>)}

                </NativeSelect>
              </TableCell>
              <TableCell align="left"><Button onClick={() => {
                if(tagLinkInput && tagLabelInput && tagIconInput){
                  addToArray({label: tagLabelInput, link: tagLinkInput, icon: tagIconInput})
                }
                }}>Add New</Button></TableCell>
            </TableRow>
          </>
          
        </TableBody>
      </Table>
    </TableContainer>          
          <Grid item={true} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
            >
              {!pending && <span>{props.buttonText}</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
        </Grid>

        
        </Grid>
      </form>
      </Container>
    </>
  );
}

export default DetailUpdate;
