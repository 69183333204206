import React, { useState, useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useAuth} from '../auth/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const authContext = useAuth();
    const { isAuthenticated, loadUser, logout, user } = authContext;

    useEffect(() => {
        if(!isAuthenticated){
            (async function() {
                await loadUser();
            })();
        }
      }, [user]);

    if(user === null) {
        return <div>Loading...</div>
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                if(user === undefined) {
                    return <Redirect to='/login' />
                }
                return <Component {...props} isAuthenticated={isAuthenticated} logout={logout}
                />
            }
                
            }
        />
    );
};

export default PrivateRoute;
