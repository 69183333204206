import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    NEW_PASSWORD_REQUIRED,
    ACCOUNT_COMPLETE_FINISHED,
    ACCOUNT_COMPLETE_FAILED,
    FORGOTTEN_PASSWORD_SUCCESS,
    FORGOTTEN_PASSWORD_FAIL,
    FORGOTTEN_PASSWORD_SENT
} from '../types';

// eslint-disable-next-line
export default (state, action) => {
    switch (action.type) {
        case FORGOTTEN_PASSWORD_SENT:
            return {
                ...state,
                passwordCodeSent: true
            }
        case FORGOTTEN_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordRequestSent: true
            };
        case USER_LOADED:
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                ...state,
                user: action.user,
                isAuthenticated: true,
                isSendingRequest: false
            };
        case ACCOUNT_COMPLETE_FINISHED:
            return {
                ...state,
                user: action.user,
                isAuthenticated: true,
                accountSetupDone: true
            };
        case NEW_PASSWORD_REQUIRED:
            return {
                ...state,
                user: action.user,
                isAuthenticated: true,
                isSendingRequest: false,
                newPasswordRequired: true,
                error: null
            };
        case LOGOUT:
        case ACCOUNT_COMPLETE_FAILED:
        case LOGIN_FAIL:
            return {
                ...state,
                error: action.action,
                isSendingRequest: false
            }
        case REGISTER_FAIL:
        case AUTH_ERROR:
            return {
                ...state,
                ...action.payload,
                user: undefined,
                isAuthenticated: false,
                error: action.payload,
                isSendingRequest: false
            };
        case FORGOTTEN_PASSWORD_FAIL:
            return {
                ...state,
                ...action.payload,
                user: undefined,
                isAuthenticated: false,
                error: action.error.message,
                isSendingRequest: false,
                passwordRequestSent: false
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
