import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import appStore from '../images/appStore.png';
import email from '../images/email.png';
import facebook from '../images/facebook.png';
import git from '../images/git.png';
import github from '../images/github.png';
import googlePlay from '../images/googlePlay.png';
import instagram from '../images/instagram.png';
import linkedin from '../images/linkedin.png';
import playStore from '../images/playStore.png';
import phone from "../images/phone.png";
import safari from '../images/safari.png';
import skype from '../images/skype.png';
import soundcloud from '../images/soundcloud.png';
import twitter from '../images/twitter.png';
import whatsapp from '../images/whatsapp.png';
import youtube from '../images/youtube.png';

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  avatarWrapper: {
    display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: 'auto'
  },
}));

const configuration = {
  "app-store": appStore,
  email: email,
  facebook: facebook,
  git: git,
  github: github,
  "google-play": googlePlay,
  instagram: instagram,
  linkedin: linkedin,
  playstore: playStore,
  phone: phone,
  safari: safari,
  skype: skype,
  soundcloud: soundcloud,
  twitter: twitter,
  whatsapp: whatsapp,
  youtube: youtube
}

function SocialLinks(props) {
  const classes = useStyles();

  const items = [];
  if(props.facebook) items.push({
    avatar: configuration["facebook"],
    link: props.facebook,
    tag: "Facebook"
  });
  if(props.linkedIn) items.push({
    avatar: configuration["linkedin"],
    link: props.linkedIn,
    tag: "LinkedIn"
  });
  if(props.twitter) items.push({
    avatar: configuration["twitter"],
    link: props.twitter,
    tag: "Twitter"
  });
  if(props.github) items.push({
    avatar: configuration["github"],
    link: props.github,
    tag: "GitHub"
  });
  if(props.instagram) items.push({
    avatar: configuration["instagram"],
    link: props.instagram,
    tag: "Instagram"
  });

  if(props.custom){
    props.custom.forEach(item => {
      items.push({
        avatar: configuration[item.icon],
        link: item.link,
        tag: item.label
      });
    });
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={6} sm={4} md={3} key={index}>
              <Box className={classes.avatarWrapper}>
                <a href={item.link} style={{textDecoration: "none"}}>
                  <Avatar
                    src={item.avatar}
                    className={classes.avatar}
                  />
                  <Typography style={{paddingTop: "5px", color: "#666A86"}} align="center">{item.tag}</Typography>
                </a>
              </Box>
              <Box textAlign="center" pt={3}>
                <Typography variant="body2" component="p">
                  {item.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {item.role}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default SocialLinks;
