import React from "react";
import IndexPage from "./index";
import CardPage from "./card";
import TagListPage from "./tag-list";
import { Switch, Route, Router } from "./../util/router";
import UpdatePage from "./detail-update";
import NotFoundPage from "./404";
import "./../util/analytics";
import { ThemeProvider } from "./../util/theme";
import Login from "./login";
import CompleteSignup from "./complete-signup";
import AuthProvider from '../auth/AuthProvider';
import PrivateRoute from "../routing/private-route";
import { Amplify } from "aws-amplify";
import { COGNITO } from "../util/aws";
import { createBrowserHistory } from 'history';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Privacy from "./privacy";
import ForgottenPassword from "./forgotten-password";

export const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    region: COGNITO.REGION,
    userPoolId: COGNITO.USER_POOL_ID,
    userPoolWebClientId: COGNITO.APP_CLIENT_ID,
    authenticationFlowType: "USER_SRP_AUTH",
    // storage: MemoryStorageNew,
    cookieStorage: {
      domain: "tagtap.uk",
      secure: true,
      path: "/",
      expires: 365,
    },
  }
});


function App(props) {
  return (
      <ThemeProvider>
        <AuthProvider>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={IndexPage} />
              <Route exact path="/scan/:id" component={CardPage} />
              <Route exact path="/u/:id" component={CardPage} />

              <Route
                exact
                path='/legal/privacy-policy'
                component={Privacy}
              />


              <PrivateRoute
                exact
                path="/tags/:id"
                component={UpdatePage}
              />

              <PrivateRoute
                exact
                path="/tags"
                component={TagListPage}
              />

              <Route
                exact
                path='/login'
                component={Login}
              />
              <Route
                exact
                path='/complete'
                component={CompleteSignup}
              />
              <Route
                exact
                path='/forgotten-password'
                component={ForgottenPassword}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </Router>
          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="TagTapPrivateCookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
            <span>Check out our privacy notice for further info.</span>
          </CookieConsent>
        </AuthProvider>
      </ThemeProvider>
  );
}

export default App;
