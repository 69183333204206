import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { useRouter } from "../util/router";
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../auth/authContext';
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  map: {
    border: 0,
    width: "100%",
    height: "320px",
    backgroundColor: "#efefef",
    // Use some CSS to give the embedded map a dark mode
    // since Google Maps embeds don't support it yet.
    filter: theme.palette.type === "dark" && "invert(90%)",
  },
}));

function ForgottenPasswordSection(props) {
  const classes = useStyles();
  const router = useRouter();

  const [user, setUser] = useState({
    email: '',
    password: '',
    code: ''
  });

  const { email, password, code } = user;

  const onChange = (e) =>
      setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
      e.preventDefault();
      props.clearErrors();
      if (password === '' || email === '' || code === '') {
        //   setAlert('Please fill in all fields', 'danger');
      } else {
          await props.setNewPassword({
              email,
              password,
              code
          });
      }
  };

  if(props.passwordRequestSent && props.passwordCodeSent){
    return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">
      <h3>Your password has been reset, please now login!</h3>
      </Container>
      </Section>
    )
    
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">

        <div className='form-container'>
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                    <span>                        
                        <TextField
                          variant="outlined"
                          type="email"
                          label="Email Address"
                          name="email"
                          fullWidth={true}
                          value={email}
                          onChange={onChange}
                          required
                        />
                    </span>
                </div>
                <p />
                {
                  !props.passwordCodeSent ? <Button variant="outlined" onClick={()=>{
                    if(email != "") props.forgottenPassword(email);
                  }}>Send for code</Button> : <></>
                }

                <p />
                <div className='form-group'>
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                      name="password"
                      fullWidth={true}
                      value={password}
                      onChange={onChange}
                      disabled={!props.passwordCodeSent}
                      required
                    />
                </div>
                <p />
                <div className='form-group'>
                    <TextField
                      variant="outlined"
                      type="code"
                      label="Code"
                      name="code"
                      fullWidth={true}
                      value={code}
                      onChange={onChange}
                      disabled={!props.passwordCodeSent}
                      required
                    />
                </div>
                <p />
                <span>
                
                <Button
                  variant="outlined"
                  type='submit'
                  value='login'
                  disabled={!props.passwordCodeSent}
                >Submit</Button>
                </span>
               
            </form>
            
        </div>
  
        { props.error &&
              <h3 className="error"> { props.error } </h3> }

        
      </Container>
    </Section>
  );
}

export default ForgottenPasswordSection;
